<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="content">
            <div class="item" @click="goPage('article')">
                <div>
                    <span>收藏的帖子</span>
                </div>
                <img src="../../assets/images/right_arrow.png" alt="">
            </div>
            <div class="item" @click="goPage('huagui')">
                <div>
                    <span>收藏的智能规</span>
                </div>
                <img src="../../assets/images/right_arrow.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: #eee;
        .content{
            width: 100%;
            height: 80px;
            background-color: #fff;
            border-top: 1px solid #eee;
            .item{
                width: 100%;
                height: 40px;
                padding: 0 8px;
                box-sizing: border-box;
                border-bottom: 1px solid #eee;
                display: flex;
                align-items: center;
                justify-content: space-between;
                vertical-align: middle;
                span{
                    font-size: 14px;
                    vertical-align: middle;
                }
                img{
                    width: 12px;
                    height: 12px;
                    vertical-align: middle;
                }
                &:active{
                    background-color: #ddd;
                }
            }
        }
    }
</style>